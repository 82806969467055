import { ICategoryDocument } from 'shared/models/Analysis-Document';
import { IBid } from 'shared/models/Bid';
import { IMortgageFlat } from 'shared/models/Mortgage';
import { LQAValidationResultKey } from 'shared/models/Validations';
import { LQAValidationResultStatus } from 'state-management/slices/quality/types';

export interface QualityFeature {
  errors: number;
  isLoading: boolean;
  isPending: boolean;
}

export interface IAllLoansTableData
  extends Pick<IMortgageFlat, 'internalId' | 'plannedClosingDate' | 'status' | 'type' | 'loanNumber' | 'loanProgram'> {
  closeChecklist: QualityFeature;
  documentCategorization: QualityFeature;
  loanQualityAdvisor: QualityFeature;
  deliverBy?: IBid['deliveryDate'] | null;
  expectedCloseDate?: IMortgageFlat['plannedClosingDate'] | null;
  complete?: boolean;
}

export interface IChecklistReviewTableBaseData {
  docId: ICategoryDocument['documentId'];
  // TODO consider consolidating missingDocument/isProcessing/rulesToReview to 1 type, status
  missingDocument: boolean;
  isProcessing: boolean;
  isDocumentError: boolean;
  categoryCount: number;
}

export interface IChecklistReviewTableDetailData extends IChecklistReviewTableBaseData {
  label: string;
}

export interface IChecklistReviewTableData extends IChecklistReviewTableBaseData {
  category: string;
  categoryCountToComplete: number;
  childItems: IChecklistReviewTableDetailData[];
  hasMultiDocs?: boolean;
}

export interface IValidationsTableData {
  label: string;
  value: number;
  type: LQAValidationResultKey;
}

export interface ILQAErrorsDefaultTableData {
  name: string;
  message: string;
  result: LQAValidationResultStatus;
}

export interface ILQAErrorsLPADataCompareTableData {
  dataField: string;
  lqaValue: string;
  lpaValue: string;
  result: LQAValidationResultStatus;
  message: string;
  nested: boolean;
}

export type TLQAErrorsTableData = ILQAErrorsDefaultTableData | ILQAErrorsLPADataCompareTableData;

export enum RuleSections {
  CORRECTIONS_NEEDED = 'Verifications Needed',
  COMPLETED = 'Completed',
  UPDATES_PENDING = 'Updates Pending',
  VERIFIY_MATCHES = 'Verify Matches'
}
