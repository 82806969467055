import { BrowserOptions, captureConsoleIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const SENTRY_CONFIG: BrowserOptions = {
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    captureConsoleIntegration({
      levels: ['error']
    }),
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['*.novaprime.com']
};
