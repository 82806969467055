import { SERVICER_PERSIST_NAME } from '../slices/servicer/servicer.slice';
import { RootState } from '../store';
import { SERVICING_RATE_PERSIST_NAME } from '../slices/servicing-rate/servicing-rate.slice';
import { bulkStateVersion, initialBulkSliceState } from '../slices/bulk/bulk.slice';
import { PERSISTED_BULK_STORAGE_KEY } from '../slices/bulk/types';
import { initialSingleBulkState, PERSISTED_SINGLE_BULK_STORAGE_KEY } from '../slices/single-bulk/slice';
import { initialInvestorState, investorStateVersion, INVESTOR_PERSIST_NAME } from '../slices/investor/investor.slice';
import { _getPersistedChunk } from './utils';
import {
  PERSISTED_QUALITY_CONTROL_STORAGE_KEY,
  QualityControlInitialState
} from 'state-management/slices/quality-control/quality-control.slice';

const getPreloadedState = (): Pick<
  RootState,
  'servicers' | 'servicingRate' | 'bulk' | 'singleBulk' | 'investor' | 'qualityControl'
> => {
  return {
    servicers: _getPersistedChunk(SERVICER_PERSIST_NAME),
    servicingRate: _getPersistedChunk(SERVICING_RATE_PERSIST_NAME),
    investor: _getPersistedChunk(INVESTOR_PERSIST_NAME, initialInvestorState, investorStateVersion),
    bulk: _getPersistedChunk(PERSISTED_BULK_STORAGE_KEY, initialBulkSliceState, bulkStateVersion),
    singleBulk: _getPersistedChunk(PERSISTED_SINGLE_BULK_STORAGE_KEY, initialSingleBulkState),
    qualityControl: _getPersistedChunk(PERSISTED_QUALITY_CONTROL_STORAGE_KEY, QualityControlInitialState)
  };
};

export default getPreloadedState;
