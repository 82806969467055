import { useQuery } from '@tanstack/react-query';
import { MortgageRepository } from 'shared/api/repositories/MortgageRepository/MortgageRepository';
import { IMortgageFlat } from 'shared/models/Mortgage';
import { mixpanelPerformanceEvents, mixpanelTrack } from '../../../../shared/mixpanel';

const mortgageRepository = new MortgageRepository();

const useGetMortgageByIdQuery = (mortgageId: IMortgageFlat['internalId']) =>
  useQuery({
    enabled: Boolean(mortgageId),
    queryKey: ['mortgages', mortgageId],
    queryFn: async ({ queryKey }) => {
      const startReqTime = performance.now();
      const response = await mortgageRepository.getOne(queryKey[1]);
      const endReqTime = performance.now();

      // Track until the end Use a try-catch block to avoid breaking the app if mixpanel fails
      try {
        mixpanelTrack(mixpanelPerformanceEvents.P_SINGLE_LOAN, { time: endReqTime - startReqTime, mortgageId });
      } catch {
        console.error(`[useGetMortgageByIdQuery] Fetch time: ${endReqTime - startReqTime} ms`);
      }

      return response;
    },
    select: (response) => response.data
  });

export default useGetMortgageByIdQuery;
