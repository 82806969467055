import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IBid } from 'shared/models/Bid';
import {
  selectFilteredOpenMortgages,
  selectFilteredPendingMortgages,
  selectOpenMortgages,
  selectPendingMortgages
} from '../mortgage/mortgage.selectors';
import { IAvailableMortgageTableData, IFinalizeTableData, IMortgageFlat } from '../../../shared/models/Mortgage';
import {
  addNestedBids,
  addOutbyToBids,
  getBidsWithPayups,
  getBidsWithPayupsSortedByAccepted,
  getFormattedAndSortedPayups
} from '../../../shared/components/BidFlow/OriginatorBidRows/use-get-bids-with-payups';
import { makeSelectPayupsByMortgageId } from '../payup/payup.selectors';
import { IPayup } from '../../../shared/models/Payup';
import { createDeepEqualSelector } from '../../utils/selectors';
import { selectServicingRates } from '../servicing-rate/servicing-rate.selectors';
import { getBidKey, getBidMap, isBidInDelivery } from './bid.utils';
import { TServicingRateByBid } from '../servicing-rate/servicing-rate.slice';
import { selectSelectedInvestors } from '../investor/investor.selectors';
import groupBy from 'lodash/groupBy';
import { setMeasurement } from '@sentry/react';
import { IInvestorProcessed } from '../../../shared/models/Investor';

export const selectBidsByKey = (state: RootState) => state.bids.bids;
export const selectIsBidsLoaded = (state: RootState) => state.bids.initialLoadComplete;
export const selectBids = createSelector(selectBidsByKey, (bids) => Object.values(bids));

export type BidsByMortgageIdMap = { [mortgageId: string]: Array<IBid> };

// TODO: This is re-memoized every time the bids change
export const selectOpenBidMap = createSelector(selectOpenMortgages, selectBids, getBidMap);

export const selectPendingBidMap = createSelector(selectPendingMortgages, selectBids, getBidMap);

/**
 * @deprecated Use makeSelectBidsForMortgage instead
 */
export const selectBidsForMortgage = createDeepEqualSelector(
  selectBids,
  (state: RootState, mortgageId: string | undefined) => mortgageId,
  (bids, mortgageId = '') => bids.filter((bid) => bid.internalMortgageId === mortgageId) ?? []
);

export const appendBidsToMortgages = (
  mortgages: IMortgageFlat[],
  bids: IBid[],
  selectedInvestors: IInvestorProcessed[]
) => {
  return mortgages.reduce<IFinalizeTableData[]>((accum, mortgage) => {
    const lastBidForMortgage = bids.find((bid) => bid.internalMortgageId === mortgage.internalId);

    if (lastBidForMortgage) {
      const bidInvestorSelected = selectedInvestors.some((investor) => investor.party === lastBidForMortgage.agent);

      if (!selectedInvestors.length || bidInvestorSelected) {
        accum.push({ ...mortgage, bids: [lastBidForMortgage], acceptedBidDate: lastBidForMortgage.acceptedDate });
      }
    } else {
      console.error(`No bid found for mortgage ${mortgage.internalId}`);
    }

    return accum;
  }, []);
};

// The reason this is here and not in mortgages is to avoid a circular dependency
export const selectOriginatorInDeliveryMortgages = createDeepEqualSelector(
  selectFilteredPendingMortgages,
  selectBids,
  selectIsBidsLoaded,
  selectSelectedInvestors,
  (mortgages: IMortgageFlat[], bids: IBid[], isBidsLoaded, selectedInvestors): IFinalizeTableData[] | null => {
    if (!isBidsLoaded) {
      return null;
    }

    return appendBidsToMortgages(mortgages, bids, selectedInvestors);
  }
);

// The reason this is here and not in mortgages is to avoid a circular dependency
export const selectOriginatorAvailableMortgages = createDeepEqualSelector(
  selectFilteredOpenMortgages,
  selectBids,
  selectIsBidsLoaded,
  selectSelectedInvestors,
  (mortgages: IMortgageFlat[], bids: IBid[], isBidsLoaded, selectedInvestors): IAvailableMortgageTableData[] | null => {
    if (!isBidsLoaded) {
      return null;
    }

    const startTime = performance.now();

    const bidsByMortgage = groupBy(bids, 'internalMortgageId');
    const availableMortgages = mortgages.reduce((accum, mortgage) => {
      const bidsForMortgage: IBid[] = bidsByMortgage[mortgage.internalId] ?? [];

      if (!isBidInDelivery(bidsForMortgage[0])) {
        if (!selectedInvestors.length) {
          accum.push({ ...mortgage, bidsLength: bidsForMortgage.length });
        } else {
          const filteredBidsByInvestor = bidsForMortgage.filter((bid) =>
            selectedInvestors.some((investor) => investor.party === bid.agent)
          );

          // We don't want to display the mortgage if there are no bids for the selected investors
          if (filteredBidsByInvestor.length) {
            accum.push({ ...mortgage, bidsLength: filteredBidsByInvestor.length });
          }
        }
      }

      return accum;
    }, [] as IAvailableMortgageTableData[]);
    const endTime = performance.now();

    setMeasurement('availableMortgages.selector.computeTime', endTime - startTime, 'millisecond');

    return availableMortgages;
  }
);

const _getBidsWithPayups = (
  bids: IBid[],
  payups: IPayup[],
  sortAcceptedFirst = false,
  servicingRates: TServicingRateByBid
) => {
  const bidsWithPayups = getBidsWithPayups(bids, payups, servicingRates); // Sorted by final price
  const bidsWithPayupsAcceptedFirst = sortAcceptedFirst
    ? getBidsWithPayupsSortedByAccepted(bidsWithPayups)
    : bidsWithPayups;
  const bidsWithPayupsWithOutby = addOutbyToBids(bidsWithPayupsAcceptedFirst);
  const bidsWithNestedBids = addNestedBids(bidsWithPayupsWithOutby);

  let payupsByAgent;

  bidsWithNestedBids.forEach((bid) => {
    payupsByAgent = payups.filter((p) => p.agent === bid.agent);

    if (!payupsByAgent.length) {
      return;
    }

    // Add simple payups to the nested bids
    // Sorteb by payup amount
    bid.nestedBids = [...bid.nestedBids, ...getFormattedAndSortedPayups(payupsByAgent, servicingRates[getBidKey(bid)])];
  });

  return bidsWithNestedBids;
};

export const makeSelectServicingRatesForMortgageWithBids = () =>
  createDeepEqualSelector(selectBidsForMortgage, selectServicingRates, (bids, servicingRate) => {
    let bidKey: string;

    const servicingRates = bids.reduce<TServicingRateByBid>((acc, bid) => {
      bidKey = getBidKey(bid);

      acc[bidKey] = servicingRate[bidKey];

      return acc;
    }, {});

    return {
      bids,
      servicingRates
    };
  });

export const makeSelectOriginatorBids = () => {
  const selectServicingRatesForMortgageWithBids = makeSelectServicingRatesForMortgageWithBids();
  const selectPayupsByMortgageId = makeSelectPayupsByMortgageId();

  return createDeepEqualSelector(
    selectServicingRatesForMortgageWithBids,
    selectPayupsByMortgageId,
    selectSelectedInvestors,
    ({ bids, servicingRates }, payups, selectedInvestors) => {
      const nonNullPayups = payups || [];
      const filteredBidsByAgent = selectedInvestors.length
        ? bids.filter((bid) => selectedInvestors.some((investor) => bid.agent === investor.party))
        : bids;
      const bidsWithPayups = _getBidsWithPayups(filteredBidsByAgent, nonNullPayups, false, servicingRates);

      return (
        bidsWithPayups
          // Sort by firm alphabetically ascending (1st fannie, 2nd freddie, 3rd penny, etc.)
          .sort((a, b) => a.firm.localeCompare(b.firm))
      );
    }
  );
};

export const makeSelectOriginatorBidsInDelivery = () => {
  const selectServicingRatesForMortgageWithBids = makeSelectServicingRatesForMortgageWithBids();
  const selectPayupsByMortgageId = makeSelectPayupsByMortgageId();

  return createSelector(
    selectServicingRatesForMortgageWithBids,
    selectPayupsByMortgageId,
    ({ bids, servicingRates }, payups) => {
      const nonNullPayups = payups || [];
      const lastBid = bids.length ? [bids[0]] : [];

      return _getBidsWithPayups(lastBid, nonNullPayups, true, servicingRates);
    }
  );
};

export const makeSelectBidsForMortgage = () => selectBidsForMortgage;
