import { createListenerMiddleware, isAnyOf, TypedStartListening } from '@reduxjs/toolkit';
import { SERVICER_ACTIONS_TO_TRIGGER_PERSIST, SERVICER_PERSIST_NAME } from '../slices/servicer/servicer.slice';
import { AppDispatch, RootState } from '../store';
import {
  SERVICING_RATE_ACTIONS_TO_TRIGGER_PERSIST,
  SERVICING_RATE_PERSIST_NAME
} from '../slices/servicing-rate/servicing-rate.slice';
import { PERSISTED_BULK_STORAGE_KEY } from '../slices/bulk/types';
import { selectBulk } from '../slices/bulk/bulk.selectors';
import { selectSingleBulk } from '../slices/single-bulk/selectors';
import { PERSISTED_SINGLE_BULK_STORAGE_KEY } from '../slices/single-bulk/slice';
import {
  investorStateVersion,
  INVESTOR_ACTIONS_TO_TRIGGER_PERSIST,
  INVESTOR_PERSIST_NAME
} from '../slices/investor/investor.slice';
import { bulkStateVersion } from '../slices/bulk/bulk.slice';
import { selectQualityControlCompletedLoansFilter } from 'state-management/slices/quality-control/quality-control.selectors';
import { PERSISTED_QUALITY_CONTROL_STORAGE_KEY } from 'state-management/slices/quality-control/quality-control.slice';

const persistListener = createListenerMiddleware();

export type PersistStartListening = TypedStartListening<RootState, AppDispatch>;
export const startPersistListening = persistListener.startListening as PersistStartListening;

startPersistListening({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Is intended to spread multiple arguments
  matcher: isAnyOf(...SERVICER_ACTIONS_TO_TRIGGER_PERSIST),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      SERVICER_PERSIST_NAME,
      JSON.stringify({
        data: latestState.servicers,
        timestamp: Date.now()
      })
    );
  }
});

startPersistListening({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Is intended to spread multiple arguments
  matcher: isAnyOf(...SERVICING_RATE_ACTIONS_TO_TRIGGER_PERSIST),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      SERVICING_RATE_PERSIST_NAME,
      JSON.stringify({
        data: latestState.servicingRate,
        timestamp: Date.now()
      })
    );
  }
});

startPersistListening({
  predicate: (_, prevState: RootState, state: RootState) => selectBulk(prevState) !== selectBulk(state),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      PERSISTED_BULK_STORAGE_KEY,
      JSON.stringify({
        data: latestState.bulk,
        timestamp: Date.now(),
        version: bulkStateVersion
      })
    );
  }
});

startPersistListening({
  predicate: (_, prevState: RootState, state: RootState) => selectSingleBulk(prevState) !== selectSingleBulk(state),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      PERSISTED_SINGLE_BULK_STORAGE_KEY,
      JSON.stringify({
        data: latestState.singleBulk,
        timestamp: Date.now()
      })
    );
  }
});

startPersistListening({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Is intended to spread multiple arguments
  matcher: isAnyOf(...INVESTOR_ACTIONS_TO_TRIGGER_PERSIST),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      INVESTOR_PERSIST_NAME,
      JSON.stringify({
        data: latestState.investor,
        timestamp: Date.now(),
        version: investorStateVersion
      })
    );
  }
});

startPersistListening({
  predicate: (_, prevState: RootState, state: RootState) =>
    selectQualityControlCompletedLoansFilter(prevState) !== selectQualityControlCompletedLoansFilter(state),
  effect: (_, api) => {
    const latestState = api.getState() as RootState;

    localStorage.setItem(
      PERSISTED_QUALITY_CONTROL_STORAGE_KEY,
      JSON.stringify({
        data: latestState.qualityControl,
        timestamp: Date.now()
      })
    );
  }
});

export default persistListener;
