import type { formatMortgageFieldValue } from 'shared/components/MortgageFlow/MortgageDetailModal/utils.format';
import { IBid } from './Bid';

export type RateToPrice = { rate: string; basePrice: string };

export enum GovernmentType {
  Conventional = 'Conventional',
  FHA = 'FHA',
  USDARuralDevelopment = 'USDA Rural Development',
  VA = 'VA'
}

export enum MortgageOriginatorStatus {
  Draft = 'Draft',
  Open = 'Open',
  Pending = 'Pending',
  Sold = 'Sold'
}

export enum MortgageStatus {
  Draft = 'Draft',
  Open = 'Open',
  Pending = 'Pending',
  Sold = 'Sold',
  Purchased = 'Purchased',
  Deactivated = 'Deactivated',
  PendingCommit = 'Pending Commit',
  ConfirmPrice = 'Confirm Price',
  PendingApproval = 'Pending Approval',
  PriceDeclined = 'Price Declined',
  PendingConfirm = 'Committing',
  ConfirmChange = 'Confirm Change',
  FinalizeSale = 'Finalize Sale',
  FinalizingSale = 'Finalizing Sale'
}
export enum MarketplaceId {
  MCT = 'mct',
  Novaprime = 'novaprime'
}

export enum BidTapeMortgageTabs {
  Open = 'Open',
  Pending = 'Pending'
}

export enum PurposeType {
  Purchase = 'Purchase',
  Refinance = 'Refinance'
}

export enum PropertyType {
  PUD = 'PUD',
  Detached = 'Detached',
  Condominium = 'Condominium',
  DetachedCondo = 'Detached Condo',
  Attached = 'Attached',
  HighRiseCondominium = 'High Rise Condominium',
  ManufacturedHousing = 'Manufactured Housing',
  Cooperative = 'Cooperative',
  TwotoFourUnits = 'TwoToFourUnits'
}

export enum Occupancy {
  Investment = 'Investment',
  PrimaryResidence = 'Primary Residence',
  SecondHome = 'Second Home'
}

export enum LoanDocument {
  FullDocumentation = 'Full Documentation',
  InterestRateReductionRefinanceLoan = 'Interest Rate Reduction Refinance Loan',
  StreamlineWithAppraisal = 'Streamline With Appraisal',
  StreamlineWithoutAppraisal = 'Streamline Without Appraisal'
}

export enum EscrowWaived {
  NoImpounds = 'No Impounds',
  TaxesAndInsurance = 'Taxes and Insurance',
  TaxesOnly = 'Taxes Only',
  InsuranceOnly = 'Insurance Only',
  OtherOnly = 'Other Only'
}

export enum LoanProgramIdentifierType {
  CHOICEHome = 'CHOICEHome',
  CHOICERenovation = 'CHOICERenovation',
  CommunityLandTrust = 'CommunityLandTrust',
  GreenCHOICE = 'GreenCHOICE',
  HFAAdvantage = 'HFAAdvantage',
  HomePossibleMortgage = 'HomePossibleMortgage',
  IncomeBasedDeedRestrictionsTerminate = 'IncomeBasedDeedRestrictionsTerminate',
  IncomeBasedDeedRestrictionsSurvive = 'IncomeBasedDeedRestrictionsSurvive'
}

export enum State {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY'
}

export enum IncompleteFieldWarningType {
  IsNotEmpty = 'isNotEmpty'
}

interface IMortgage {
  internalId: string;
  universalLoanId: string;
  mersMIN: string;
  loanDetails: {
    automatedUnderwritingSystemType: string;
    loanProgram: LoanProgram;
    governmentType: GovernmentType;
    originalTerm: number;
    currentInterestRate: number;
    currentTerm: number;
    noteAmount: number;
    unpaidPrincipleBalance: number;
    totalAmount: number;
    purpose: PurposeType;
    loanToValue: number;
    combinedLoanToValue: number;
    debtToIncomeRatio: number;
    loanDocument: LoanDocument;
    escrowWaived: EscrowWaived;
    plannedClosingDate: string;
    creditScore: number;
    totalBorrowerIncome: number;
  };
  loanNumber: string;
  winningBidAmount: number;
  soldDate: string;
  openDate: string;
  createdDate: string;
  closedDate: string;
  soldTo: string;
  purchasedFrom: string;
  numInvestors: number;
  propertyDetails: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    propertyType: PropertyType;
    occupancy: Occupancy;
    appraisedValue: number;
    salesPrice: number;
    livingUnits: number;
  };
  extensionData: {
    termYears: '10' | '15' | '20' | '30';
    amortizationType: 'Fixed' | 'AdjustableRate';
    initialFixedPeriodEffectiveMonthsCount: '36' | '60' | '84' | '120';
    loanProgramIdentifier: LoanProgramIdentifierType[];
    documentCustodianId: string;
    payeeId: string;
    warehouseLenderId: string;
    investorCommitmentId: string;
  };
  marketplace: {
    id: MarketplaceId;
    // Additional status field that is not used
  };
  status: MortgageStatus;
  bidApprover?: string;
  bidSubmitter?: string;
  investorContractId: string;
  rateToPrice: RateToPrice[];
  incompleteFieldWarnings: string[];
}

export interface IMortgageMappable
  extends Omit<IMortgage, 'loanDetails' | 'propertyDetails' | 'status' | 'marketplace'> {
  loanDetails: {
    loanProgram: LoanProgram;
    automatedUnderwritingSystemType: string;
    governmentType: GovernmentType;
    originalTerm: number;
    currentInterestRate: number | string;
    currentTerm: number;
    noteAmount: number;
    unpaidPrincipleBalance: number;
    totalAmount: number;
    purpose: PurposeType;
    loanToValue: number | string;
    combinedLoanToValue: number | string;
    debtToIncomeRatio: number;
    loanDocument: LoanDocument;
    escrowWaived: EscrowWaived;
    ficoScore: number;
    borrowerIncome: number;
    loanProgramIdentifier: LoanProgramIdentifierType[];
  };
  propertyDetails: {
    address: string;
    city: string;
    state: string;
    zip: string;
    propertyType: PropertyType;
    occupancy: Occupancy;
    appraisedValue: number;
    salesPrice: number;
    livingUnits: number;
  };
}

interface IMortgageFlat
  extends Pick<
      IMortgage['extensionData'],
      | 'amortizationType'
      | 'termYears'
      | 'initialFixedPeriodEffectiveMonthsCount'
      | 'documentCustodianId'
      | 'payeeId'
      | 'warehouseLenderId'
      | 'investorCommitmentId'
    >,
    Pick<IMortgage, 'incompleteFieldWarnings'> {
  internalId: string;
  mersMIN: string;
  loanNumber: string;
  universalLoanId: string;
  ficoScore: number;
  automatedUnderwritingSystemType: string;
  loanProgram: LoanProgram;
  type: GovernmentType;
  originalTerm: number;
  currentTerm: number;
  currentInterestRate: number | string;
  noteAmount: number;
  unpaidPrincipleBalance: number;
  totalAmount: number;
  ltv: number | string;
  cltv: number | string;
  loanDocument: LoanDocument;
  loanProgramIdentifier: LoanProgramIdentifierType[];
  escrowWaived: EscrowWaived;
  payupName?: string | null;
  location: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  purpose: PurposeType;
  propertyType: PropertyType;
  occupancy: Occupancy;
  appraisedValue: number;
  salesPrice: number;
  livingUnits: number;
  status: MortgageStatus;
  borrowerIncome: number;
  debtToIncome: number;
  winningBidAmount: number;
  plannedClosingDate: string;
  soldDate: string;
  createdDate: string;
  openDate: string;
  closedDate: string;
  soldTo: string;
  purchasedFrom: string;
  numInvestors: number;
  bidApprover?: string;
  bidSubmitter?: string;
  investorContractId: string;
  rateToPrice: RateToPrice[];
}

export interface IFinalizeTableData extends IMortgageFlat {
  bids: IBid[];
  acceptedBidDate: IBid['acceptedDate'];
}

export interface IAvailableMortgageTableData extends IMortgageFlat {
  bidsLength: number;
}

export enum LoanProgram {
  G15 = 'G15',
  G30 = 'G30',
  GovARM = 'GovArm',
  Bond = 'Bond',
  C10 = 'C10',
  C15 = 'C15',
  C20 = 'C20',
  C30 = 'C30',
  ConvArm = 'ConvArm',
  BestEffort = 'BestEffort',
  Conventional = 'Conventional',
  FHA = 'FHA',
  VA = 'VA'
}

export enum IMortgageHistoryEntryType {
  FieldEdit = 'fieldEdit',
  Init = 'init'
}

export interface IMortgageHistoryChangeEntry {
  path: string;
  previous?: string | number | boolean;
  current: string | number | null | boolean | string[];
}

export interface IFormattedMortgageHistoryChangeEntry {
  date: string;
  propertyName: string;
  formattedPrevious: ReturnType<typeof formatMortgageFieldValue>;
  formattedCurrent: ReturnType<typeof formatMortgageFieldValue>;
  displayArrayPrefix: boolean;
}

export interface IMortgageHistoryEntry {
  type: IMortgageHistoryEntryType;
  date: string;
  changes: Array<IMortgageHistoryChangeEntry>;
}

export interface IMortgageHistory {
  id: IMortgage['internalId'];
  more: boolean;
  offset: number;
  history: IMortgageHistoryEntry[];
}

export type { IMortgage, IMortgageFlat };
