import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { authenticationReducer, AuthSlice } from './slices/authentication/authentication.slice';
import mortgageReducer, { MortgageSlice } from './slices/mortgage/mortgage.slice';
import bidTapeReducer, { BidTapeSlice } from './slices/bid-tape/bid-tape.slice';
import userReducer, { UserSlice } from './slices/user/user.slice';
import investorReducer, { InvestorSlice } from './slices/investor/investor.slice';
import bidReducer, { BidSlice } from './slices/bid/bid.slice';
import bulkReducer, { BulkSlice } from './slices/bulk/bulk.slice';
import pricingOptionReducer, { PricingOptionState } from './slices/pricingOption/pricingOption.slice';
import { tableFiltersReducer, TableFilterType } from './slices/tableFilter/tableFilter.slice';
import toastReducer, { ToastSlice } from './slices/toast/toast.slice';
import traderReducer, { TraderSlice } from './slices/trader/trader.slice';
import payupReducer, { PayupSlice } from './slices/payup/payup.slice';
import servicerReducer, { ServicerState } from './slices/servicer/servicer.slice';
import servicingRateReducer, { ServicingRateState } from './slices/servicing-rate/servicing-rate.slice';
import configReducer, { Config } from './slices/config/config.slice';
import singleBulkReducer, { SingleBulkState } from './slices/single-bulk/slice';
import qualityControlReducer, { QualityControlSlice } from './slices/quality-control/quality-control.slice';
import analysisDocumentsReducer, { IAnalysisDocumentsState } from './slices/analysis-documents/slice';
import analysisCategoriesReducer, { IAnalysisCategoriesState } from './slices/analysis-categories/slice';

import { mortgageDocumentReducer, MortgageDocumentSlice } from './slices/mortgage-document/mortgage-document.slice';
import { deliveryDateReducer, DeliveryDateSlice } from './slices/deliveryDate/delivery-date.slice';
import bidListener from './slices/bid/bid.listener';
import persistListener from './persistence/persist';
import getPreloadedState from './persistence/restore';
import bulkInitListener from './slices/bulk/bulk.listener';
import mortgageListener from './slices/mortgage/mortgage.listener';
import singleBulkListener from './slices/single-bulk/listener';
import toastListener from './slices/toast/toast.listener';
import bulkPollingListener from './slices/bulk/bulk.polling';
import documentCountListener from './slices/analysis-documents/document-count-listener';
import userListener from './slices/user/user.listener';

enableMapSet();

export const store = configureStore({
  preloadedState: getPreloadedState(),
  // NOTE: Please keep these sorted alphabetically
  reducer: {
    analysisDocuments: analysisDocumentsReducer,
    analysisCategories: analysisCategoriesReducer,
    // access: accessReducer,
    auth: authenticationReducer,
    bids: bidReducer,
    bidTape: bidTapeReducer,
    config: configReducer,
    bulk: bulkReducer,
    deliveryDate: deliveryDateReducer,
    investor: investorReducer,
    mortgage: mortgageReducer,
    mortgageDocument: mortgageDocumentReducer,
    payup: payupReducer,
    servicers: servicerReducer,
    servicingRate: servicingRateReducer,
    singleBulk: singleBulkReducer,
    tableFilters: tableFiltersReducer,
    pricingOption: pricingOptionReducer,
    toast: toastReducer,
    traders: traderReducer,
    user: userReducer,
    qualityControl: qualityControlReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      // NOTE: Since this can receive actions with functions inside, it should go before the serializability check middleware
      .prepend(
        bidListener.middleware,
        persistListener.middleware,
        bulkInitListener.middleware,
        bulkPollingListener.middleware,
        mortgageListener.middleware,
        singleBulkListener.middleware,
        toastListener.middleware,
        documentCountListener.middleware,
        userListener.middleware
      )
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type RootState = {
  analysisDocuments: IAnalysisDocumentsState;
  analysisCategories: IAnalysisCategoriesState;
  auth: AuthSlice;
  bids: BidSlice;
  bidTape: BidTapeSlice;
  config: Config;
  bulk: BulkSlice;
  deliveryDate: DeliveryDateSlice;
  investor: InvestorSlice;
  mortgage: MortgageSlice;
  mortgageDocument: MortgageDocumentSlice;
  payup: PayupSlice;
  servicers: ServicerState;
  servicingRate: ServicingRateState;
  tableFilters: TableFilterType;
  pricingOption: PricingOptionState;
  singleBulk: SingleBulkState;
  toast: ToastSlice;
  traders: TraderSlice;
  user: UserSlice;
  qualityControl: QualityControlSlice;
};
