import { IAvailableMortgageTableData, IFinalizeTableData, IMortgage, IMortgageFlat } from './Mortgage';

export enum DocumentTag {
  INITIAL = 'INITIAL',
  FINAL = 'FINAL',
  NONE = 'NONE'
}

export enum ArchiveType {
  ILLEGIBLE = 'Illegible',
  UNACCEPTABLE = 'Unacceptable',
  MISSING_PAGES = 'Missing Pages',
  DUPLICATE = 'Duplicate',
  INCOMPLETE = 'Incomplete',
  DO_NOT_USE = 'Do Not Use'
}

export type DocumentSubstate = DocumentTag | ArchiveType;

export enum DocumentSplitType {
  //This document is not split, but stands on its own
  // default
  STANDALONE = 'STANDALONE',
  //This document is a parent document, and has been split into multiple child documents
  PARENT = 'PARENT',
  //This document is a child document, which has been split from a parent document
  CHILD = 'CHILD'
}

// TODO rename to denote API response object
export interface IAnalysisDocumentPreview {
  id: string;
  fileUrl: string;
  fileName: string;
  category?: string | null;
  // mortgageId: string; // Uncomment if needed
  createdAt?: Date;
  state: AnalysisDocumentStatus;
  substate: DocumentSubstate;
  documentSplitType?: DocumentSplitType;
}
export interface ISuggestCategory {
  category: string;
  confidenceLevel: number; // float
}

export interface IAnalysisDocumentDetails extends IAnalysisDocumentPreview {
  suggestedCategories?: ISuggestCategory[];
}

export enum DocumentStatus {
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  UNSTARTED = 'UNSTARTED'
}

export interface IDocumentStatus {
  mortgageId: IMortgage['internalId'];
  status: DocumentStatus;
  totalDocuments: number;
  docsUncategorized: number;
  // totalRules: number; // Uncomment if needed
  rulesFailing: number; // Uncomment if needed
  // rulesUpdating: number;
  rulesAutoPass: number;
  // rulesOverridePass: number;
}

export interface IOpenMortgageWithDocStatus extends IAvailableMortgageTableData {
  documentStatus: DocumentStatus;
}

export interface IMortgageDocumentStatus extends IMortgageFlat {
  documentStatus: DocumentStatus;
}

export interface IFinalizeMortgageWithDocStatus extends IFinalizeTableData {
  documentStatus: DocumentStatus;
}

export enum AnalysisDocumentStatus {
  MISSING = 'MISSING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
  ARCHIVED = 'ARCHIVED'
}

export interface ICategoryDocumentAPI {
  documentId: string | null;
  fileUrl: string | null;
  uploadDate: Date | string | null;
  totalRules: number;
  rulesFailing: number;
  rulesUpdating: number;
  state: AnalysisDocumentStatus;
  rulesAutoPass: number;
  rulesOverridePass: number;
}

export interface ICategoryDocument extends ICategoryDocumentAPI {
  // How many rules are left to be marked as complete
  rulesToComplete: number; // Replaces rulesToReview
  // How many rules are left to take an action on
  rulesTodo: number;
}

export interface IAnalysisCategoryAPI {
  category: string;
  // categoryDescription: string; // Uncomment if needed
  differentiators: Array<{ differentiator: string; documents: ICategoryDocumentAPI[] }>;
  totalRules: number;
  rulesFailing: number;
  rulesUpdating: number;
  rulesAutoPass: number;
  rulesOverridePass: number;
}

// TODO rename to avoid confusion with anaylsis-category/slice
export interface IAnalysisCategory extends IAnalysisCategoryAPI {
  // How many rules are left to be marked as complete
  rulesToComplete: number; // Replaces rulesToReview
  // How many rules are left to take an action on
  rulesTodo: number;
  differentiators: Array<{ differentiator: string; documents: ICategoryDocument[] }>;
}

export interface IDocumentRegion {
  pageIndex: number;
  orientation: 'PORTRAIT' | 'LANDSCAPE';
  documentWidth: number;
  documentHeight: number;
  top: number;
  left: number;
  height: number;
  width: number;
}

export type TBoundingBox = IDocumentRegion & {
  ruleId: IAnalysisRule['ruleId'];
  groupIndex: number;
  result: IAnalysisRule['result'];
  documentId: IAnalysisDocumentPreview['id'] | null;
};

export enum RuleResult {
  NA = 'NA',
  AUTO_PASS = 'AUTO_PASS', // AI Rule
  FAIL = 'FAIL',
  OVERRIDE_PASS = 'OVERRIDE_PASS',
  PASS = 'PASS', // Use to indicate a rule that passed (user reviewed)
  DISPLAY = 'DISPLAY', // Use to indicate a rule that will not be checked
  UPDATED = 'UPDATED'
}

export enum OutputFormatTypes {
  None = 'None',
  Currency = 'Currency',
  Date = 'Date',
  SSN = 'SSN',
  Number = 'Number',
  Percent = 'Percent'
}

export enum LoanValue {
  ERROR = 'ERROR',
  NO_VALUE = 'No Value'
}

export interface IAnalysisRuleAPI {
  ruleExecutionId: string;
  name: string;
  group: string | null;
  result: RuleResult;
  engineResult: RuleResult;
  problemText?: string;
  // lastUpdated: Date | string; // Uncomment if needed
  region: IDocumentRegion | null;
  loanValue?: LoanValue | string | number;
  loanFormatType: OutputFormatTypes; // None | Currency | Date
  loanValueSource?: string;
  loanDisplayValue?: string; // Value used by rule creators to override loanValue

  documentValue?: string | object | number;
  documentFormatType: OutputFormatTypes;
  documentValueSource?: string;
  documentDisplayValue?: string;

  otherDocuments?: IComparedDocumentAPI[];

  // Use for metadata
  ruleId: string;
  version: number;
}

interface IComparedDocumentMissingAPI {
  value: string | number;
  region: null;
  category?: string;
  formatType: OutputFormatTypes;
  valueSource: string;
  documentId: undefined;
  fileUrl: undefined;
  state: undefined;
  displayValue: undefined;
}

interface IComparedDocumentCompleteAPI extends Pick<ICategoryDocumentAPI, 'documentId' | 'fileUrl' | 'state'> {
  value: string | number;
  displayValue: string;
  formatType: OutputFormatTypes;
  valueSource: string;
  category: string;
  subState: DocumentTag;
  region: IDocumentRegion;
  borrower: string;
}

type IComparedDocumentAPI = IComparedDocumentMissingAPI | IComparedDocumentCompleteAPI;

// For safety omit, value to replace it formattedValue -> uncomment if needed
interface IComparedDocument extends Omit<IComparedDocumentAPI, 'value'> {
  formattedValue: string;
}

// For type safety, omit overridden values (otherDocuments)
// For code safety, omit replaced values (loanValue) > uncomment if needed
export interface IAnalysisRule extends Omit<IAnalysisRuleAPI, 'otherDocuments' | 'loanValue' | 'documentValue'> {
  loanFormattedValue: string;
  documentFormattedValue: string;
  otherDocuments: IComparedDocument[];
}

export interface IDocumentRulesAPI {
  documentId: string | null;
  fileUrl: string | null;
  differentiator: string;
  uploadDate: Date | string | null;
  rules: IAnalysisRuleAPI[];
}

// For type safety, omit overridden values
export interface IDocumentRules extends Omit<IDocumentRulesAPI, 'rules'> {
  rules: IAnalysisRule[];
}
