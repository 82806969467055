import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface QualityControlSlice {
  searchValue: string;
  completedLoansFilter: boolean;
}

export const QualityControlInitialState: QualityControlSlice = {
  searchValue: '',
  completedLoansFilter: true
};
const qualityControlSlice = createSlice({
  name: 'qualityControl',
  initialState: QualityControlInitialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<QualityControlSlice['searchValue']>) => {
      state.searchValue = action.payload;
    },
    setCompletedLoansFilter: (state, action: PayloadAction<QualityControlSlice['completedLoansFilter']>) => {
      state.completedLoansFilter = action.payload;
    }
  }
});

export const PERSISTED_QUALITY_CONTROL_STORAGE_KEY = 'QUALITY_CONTROL';

export const { setSearchValue, setCompletedLoansFilter } = qualityControlSlice.actions;
export default qualityControlSlice.reducer;
